import { Component, OnInit, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { UsersService } from "./users.service";
import { MatTableDataSource } from "@angular/material/table";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from "@angular/material/dialog";
import { FormBuilder } from "@angular/forms";
import { Validators } from "@angular/forms";
import * as _ from "lodash";

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.scss"]
})
export class UsersComponent implements OnInit {
  userList: any;
  usersAptList: any;
  doorList: any;
  animal: string;
  name: string;
  selectedUser: any;
  dataSource: any;
  displayedColumns: string[] = [
    "aptnumber",
    "firstName",
    "lastName",
    "email",
    "phone",
    "edit",
    "delete"
  ];

  constructor(private appService: UsersService, public dialog: MatDialog) {}

  ngOnInit() {
    // Get user list
    // this.appService.getUserList().subscribe(
    //   data => {
    //     this.userList = data;
    //     console.log("in get users list: ", this.userList);
    //   },
    //   error => console.log("error: ", error)
    // );

    // Get user list by apt
    this.getAptUsersList();
    // this.appService.getUserAptList().subscribe(
    //   data => {
    //     this.usersAptList = data;
    //     console.log("in get users apt list: ", this.usersAptList);
    //   },
    //   error => console.log("error: ", error)
    // );

    // Get door list by apt
    // this.appService.getDoorList().subscribe(
    //   data => {
    //     this.doorList = data;
    //     console.log("in get doors apt list: ", this.doorList);
    //   },
    //   error => console.log("error: ", error)
    // );
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(UserDialogBox, {
      width: "500px",
      data: this.selectedUser || {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("The dialog was closed");
      this.selectedUser = null;

      if (result) {
        this.getAptUsersList();
      }
    });
  }

  deleteConfirmationDialog(id): void {
    const dialogRef = this.dialog.open(UserDeleteDialogBox, {
      width: "500px",
      data: id
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("The delete dialog was closed");
      if (result) {
        this.getAptUsersList();
      }
    });
  }

  getAptUsersList() {
    this.appService.getUserAptList().subscribe(
      data => {
        this.usersAptList = data;
        console.log("in get users apt list: ", this.usersAptList);
        this.dataSource = new MatTableDataSource(this.usersAptList);
      },
      error => console.log("error: ", error)
    );
  }

  userFetched(id) {
    this.selectedUser = _.find(this.usersAptList, { id });
    this.openDialog();
  }

  deleteUser(id) {
    console.log("delete user: ", id);
    this.appService.deleteUser(id).subscribe(
      res => {
        console.log("user deleted: ", res);
      },
      err => {
        console.log(err);
      }
    );
  }
}

@Component({
  selector: "user-dialog-box",
  templateUrl: "user-dialog-box.html",
  styleUrls: ["./users.component.scss"]
})
export class UserDialogBox {
  profileForm = this.fb.group({
    firstName: ["", Validators.required],
    lastName: ["", Validators.required],
    email: ["", Validators.required],
    phone: ["", Validators.required],
    aptnumber: ["", Validators.required]
  });
  apiFunction: string;
  id: string = "";
  headerText: string = "Add";

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<UserDialogBox>,
    @Inject(MAT_DIALOG_DATA) public data,
    private appService: UsersService
  ) {
    if (Object.keys(data).length) {
      this.id = data.id;
      this.apiFunction = "updateUser";
      this.headerText = "Update";
      const { id, aptID, ...userFormData } = data;
      if (userFormData) {
        this.profileForm.patchValue(userFormData);
      }
    } else {
      this.apiFunction = "postUser";
    }
  }

  onSubmit(): void {
    let userDetails = {
      ...this.profileForm.value,
      propertyName: "49960515-8D1F-422B-B042-18C5E8D0D1C5",
      maindoor: "5DEC8E66-DAD1-4A53-B7C2-7A699AFED63A",
      id: this.id
    };

    this.appService[this.apiFunction](userDetails).subscribe(
      res => {
        console.log("data posted: ", res);
        this.dialogRef.close(true);
      },
      err => {
        console.log("error: ", err);
        this.dialogRef.close();
      }
    );
  }
}

@Component({
  selector: "user-delete-dialog-box",
  templateUrl: "user-delete-dialog-box.html",
  styleUrls: ["./users.component.scss"]
})
export class UserDeleteDialogBox {
  constructor(
    public dialogRef: MatDialogRef<UserDeleteDialogBox>,
    @Inject(MAT_DIALOG_DATA) public data,
    private appService: UsersService
  ) {}

  onSubmit(): void {
    this.appService.deleteUser(this.data).subscribe(
      res => {
        console.log("data deleted: ", res);
        this.dialogRef.close(true);
      },
      err => {
        console.log("error: ", err);
        this.dialogRef.close(true);
      }
    );
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
