import { Component, OnInit, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { DoorsService } from "./doors.service";
import { MatTableDataSource } from "@angular/material/table";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from "@angular/material/dialog";
import { FormBuilder } from "@angular/forms";
import { Validators } from "@angular/forms";
import * as _ from "lodash";

@Component({
  selector: "app-doors",
  templateUrl: "./doors.component.html",
  styleUrls: ["./doors.component.scss"]
})
export class DoorsComponent implements OnInit {
  userList: any;
  usersAptList: any;
  doorList: any;

  name: string;
  selectedUser: any;
  dataSource: any;
  displayedColumns: string[] = ["aptnumber", "edit"];

  constructor(private appService: DoorsService, public dialog: MatDialog) {}

  ngOnInit() {
    // Get user list
    // this.appService.getUserList().subscribe(
    //   data => {
    //     this.userList = data;
    //     console.log("in get users list: ", this.userList);
    //   },
    //   error => console.log("error: ", error)
    // );

    // Get user list by apt
    this.getAptUsersList();
    // this.appService.getUserAptList().subscribe(
    //   data => {
    //     this.usersAptList = data;
    //     console.log("in get users apt list: ", this.usersAptList);
    //   },
    //   error => console.log("error: ", error)
    // );

    // Get door list by apt
    // this.appService.getDoorList().subscribe(
    //   data => {
    //     this.doorList = data;
    //     console.log("in get doors apt list: ", this.doorList);
    //   },
    //   error => console.log("error: ", error)
    // );
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DoorDialogBox, {
      width: "500px",
      data: this.selectedUser || {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("The dialog was closed");
      this.selectedUser = null;

      if (result) {
        this.getAptUsersList();
      }
    });
  }

  getAptUsersList() {
    this.appService.getUserAptList().subscribe(
      data => {
        this.usersAptList = data;
        console.log("in get users apt list: ", this.usersAptList);
        this.dataSource = new MatTableDataSource(this.usersAptList);
      },
      error => console.log("error: ", error)
    );
  }

  userFetched(id) {
    this.selectedUser = _.find(this.usersAptList, { id });
    this.openDialog();
  }
}

@Component({
  selector: "door-dialog-box",
  templateUrl: "door-dialog-box.html",
  styleUrls: ["./doors.component.scss"]
})
export class DoorDialogBox {
  profileForm = this.fb.group({
    doorName: ["", Validators.required]
  });
  apiFunction: string;
  id: string = "";
  headerText: string = "Add";

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<DoorDialogBox>,
    @Inject(MAT_DIALOG_DATA) public data,
    private appService: DoorsService
  ) {
    if (Object.keys(data).length) {
      this.id = data.id;
      this.apiFunction = "updateUser";
      this.headerText = "Update";
      const { id, aptID, ...userFormData } = data;
      if (userFormData) {
        this.profileForm.patchValue(userFormData);
      }
    } else {
      this.apiFunction = "postUser";
    }
  }

  onSubmit(): void {
    let userDetails = {
      ...this.profileForm.value,
      aptID: "49960515-8D1F-422B-B042-18C5E8D0D1C5",
      id: this.id
    };

    this.appService[this.apiFunction](userDetails).subscribe(
      res => {
        console.log("data posted: ", res);
        this.dialogRef.close(true);
      },
      err => {
        console.log("error: ", err);
        this.dialogRef.close();
      }
    );
  }
}
